import React from 'react';
import logo from './logo.svg';
import './App.css';
import MainComponent from  './components/mainComponent';
import {BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <div>
      <MainComponent/>
      </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
