export const DEVSERVERIP = "192.168.2.9";
export const DEVSERVERPORT = 8080;
export const DEVPROTOCOL="http://";
export const DEVDOMAIN="localhost";

export const TESTSERVERIP = "localhost";
export const TESTSERVERPORT = 8080;
export const TESTSERVERPROTOCOL="http://";
export const TESTDOMAIN = TESTSERVERIP+':'+TESTSERVERPORT;

export const PRODSERVERIP = "shiva-driving-school.herokuapp.com";
export const PRODSERVERPORT = null;
export const PRODPROTOCOL = "https://";
export const PRODDOMAIN = "shiva-driving-school.herokuapp.com";


export const PROD2SERVERIP = "shivadrivingschool.herokuapp.com";
export const PROD2SERVERPORT = null;
export const PROD2PROTOCOL = "https://";
export const PROD2DOMAIN = "www.shivadrivingatlanta.com";



/*
export const SERVERIP = TESTSERVERIP;
export const SERVERPORT = TESTSERVERPORT;
export const PROTOCOL = TESTSERVERPROTOCOL;
export const URL = PROTOCOL+SERVERIP+':'+TESTSERVERPORT;
export const SECURE = false;
*/

/*
export const SERVERIP = DEVSERVERIP;
export const SERVERPORT = DEVSERVERPORT;
export const PROTOCOL = DEVPROTOCOL;
export const URL = PROTOCOL+SERVERIP+':'+DEVSERVERPORT;
export const SECURE = false;
export const DOMAINIP  = DEVDOMAIN;
*/


/*
export const SERVERIP = PRODSERVERIP;
export const SERVERPORT = PRODSERVERPORT;
export const PROTOCOL = PRODPROTOCOL;
export const URL = PROTOCOL+SERVERIP
export const SECURE = true;
export const DOMAINIP  = PRODDOMAIN;
*/



export const SERVERIP = PROD2SERVERIP;
export const SERVERPORT = PROD2SERVERPORT;
export const PROTOCOL = PROD2PROTOCOL;
export const URL = PROTOCOL+SERVERIP;
export const SECURE = true;
export const DOMAINIP  = PROD2DOMAIN;

