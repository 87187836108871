import React,{Component} from 'react';
import Cookies from 'universal-cookie';
import * as Constants from './Constants/constantData';
import {
  Redirect
} from 'react-router-dom';

class Logoff extends Component {

  constructor(props){
    super(props);
  }

  refreshPage(){
    window.location.reload();
  }


render(){
    const cookies = new Cookies();
    if(cookies.get('shivaDStoken') !== undefined || cookies.get('shivaDStoken') != null ){
      cookies.remove('shivaDStoken',{ path: '/',maxAge:1200,domain:Constants.SERVERIP,secure:Constants.SECURE });
      cookies.remove('shivaDStoken',{ path: '/',maxAge:1200,domain:Constants.DOMAINIP,secure:Constants.SECURE });
      this.refreshPage();
    }
    
  return(
    <div>
  <h3>You Have sucessfully Logged Off.</h3>
  <div>Thank you for choosing us.</div>
  </div>
  );

}

}

export default Logoff;
