import React from 'react';
import { Link } from 'react-router-dom';
import "../stylesheets/footer.css";
import Images from './ImageConstants/BackgroundConst';

function Footer(props){
  return (
    <div className="footer">
        <div className="container-fluid">
            <div className="row w-100 justify-content-center">
                <div className="col-sm-2"></div>
                <div className="col-6 col-sm-4">
                    <h3>Links</h3>
                    <ul className="list-unstyled">
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/static/aboutus">About Us</Link></li>
                        <li><Link to="/static/services">Services</Link></li>
                        <li><Link to="/static/auth">Login</Link></li>
                        <li><Link to="/contactus">Contact</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-sm-4">
                    <h3></h3>
                    <address>
		              Lawrenceville, GA<br />
		              Zip Code - 30046<br />
		              <i className="fa fa-phone fa-lg"></i>: +1 404-980-2197<br />
		              <i className="fa fa-envelope fa-lg"></i>: <a href="mailto:info@shivadrivingatlanta.com">
                        info@shivadrivingatlanta.com</a>
                    </address>
                </div>
                <div className="col-sm-2"></div>
            </div>
            <div className="row w-100 justify-content-center">
                <div className="col-12 col-sm-12 align-self-center">
                    <div className="text-center">
                        <a className="btn btn-social-icon btn-google" href="http://google.com/+"><i className="fa fa-google-plus"></i></a>
                        <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/shivadrivingschool"><i className="fa fa-facebook"></i></a>
                        <a className="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/"><i className="fa fa-linkedin"></i></a>
                        <a className="btn btn-social-icon btn-google" href="http://youtube.com/"><i className="fa fa-youtube"></i></a>
                        <a className="btn btn-social-icon" href="mailto:"><i className="fa fa-envelope-o"></i></a>
                    </div>
                </div>
            </div>
            <div className="row w-100 justify-content-center">
                <div className="col-auto">
                    <img src={Images.allCards}></img>
                </div>
                <div className="col-auto">
                    <img src={Images.stripe}></img>
                </div>
            </div>
            <div className="row w-100 justify-content-center">
                <div className="col-auto">
                    <p>© Copyright 2020 </p>
                    <p><a href="https://www.freepik.com/free-photos-vectors/car">Car vector created by freepik - www.freepik.com</a></p>
                    <span>Photo by <a href="https://unsplash.com/@myzhik1988?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Aleksandr Kozlovskii</a> on <a href="https://unsplash.com/photos/pyz7R5ntkeg?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Footer;
