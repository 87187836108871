import React,{Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import '../stylesheets/navbar.css';
import logo from '../ImageConstants/ImgConstants.js';
import Cookies from 'universal-cookie';
import {NavLink} from 'react-router-dom';

class NavComponent extends  Component{
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      token:'',
      isOpen:true,
    };
  }



  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

render(){
    let loginOrOff;
    let userInfo;
    const cookies = new Cookies();
    if(cookies.get('shivaDStoken')===null||
          cookies.get('shivaDStoken')===undefined) {
            loginOrOff= <NavLink to="/static/auth">Login</NavLink>;
            //userInfo = <NavLink className="black" href="">User Info N/A</NavLink>;
          }
    else {
      loginOrOff = <NavLink to="/static/logoff">Logoff</NavLink>;
      userInfo =  <NavLink  to="/static/userInfo">User Info</NavLink>;
    }

  return(
    <Navbar dark expand="md" className="w-100 thick-nav bg-black">
        <img src={logo.logo} width='3%' height="3%"/>
        <NavbarBrand href="/">Home</NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} />
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/static/services" activeClassName="active">Services</NavLink>
            </NavItem>
            <NavItem>
              {loginOrOff}
            </NavItem>
            <NavItem>
              {userInfo}
            </NavItem>
            <NavItem>
              <NavLink to="/static/aboutus" activeClassName="active">About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/static/contactUSM" activeClassName="active">Contact Us</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
  );
}
}

export default NavComponent;
