import React,{Component,Fragment} from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import Login from './LoginComponent';
import SignUp from './SignUpComponent';
import { Nav, NavItem } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import '../stylesheets/loginSignup.css';
import ForgotPassword from './ForgotPasswordComponent';



  class LoginOrSignUp extends Component {
    constructor(props){
      super(props);
    }

    render(){
      return(

        <div>
        <Nav tabs>
         <NavItem>
           <NavLink className="nav-link" to="/static/auth/SignIn" >Sign In</NavLink>
         </NavItem>
         <NavItem>
           <NavLink className="nav-link" to="/static/auth/SignUp">Sign Up</NavLink>
         </NavItem>
        </Nav>

        <Switch>
          <Fragment>
            <Route exact path="/static/auth/SignIn"
             render={(props) => <Login {...props} isAuthed={this.props.loginAuthAction} />} />
            <Route exact path="/static/auth/SignUp" component={SignUp} />
            <Route exact path="/static/auth/ForgotPassword" component={ForgotPassword} />
            <Redirect to = "/static/auth/SignIn" />

          </Fragment>
        </Switch>

        </div>

      );
    }


  }

export default LoginOrSignUp;
