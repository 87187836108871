import React,{Component} from 'react';
import '../stylesheets/aboutus.css';
import Gallery from './GalleryComponent';

class AboutUs extends Component {

  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount(){
    window.scrollTo(0, this.myRef.current.offsetTop);   
  }
  

render(){
return(
    <div ref={this.myRef}>
      <div className="container">
      <br/>
        <div className="row">
          <div className="col-sm-12 about-us-page">
            <h2 className='heading'>ABOUT US</h2>
            <span className="highlight-red">Shiva Driving School</span> in atlanta is a trusted and tested name for all
            your driving needs to be a safe and skilled driver.
            We are providing quality driving lessons in <span className="highlight-blue">Gwinnett, Dekalb, Cobb, Fulton and Forsyth </span>
            county and many more cities of Metro Atlanta.<br/>
            Our Unique approach helps develop proper attitude towards safe driving. Our programs include 30 Hours of
            online lectures and atleast 6 hours of in-car instructions and driving observations for teenagers as per
            Georgia Joshuas law.
            <br/>
            We have customed tailored programs just to meet your requirements to get Georgia Driving License.
            Individual driving school lessons are offered in 2 hours minimum block of time. We are licensed and certified to conduct
            the Georgia drivers road test.
            <br/>
            All our instructors are highly patient, state certified, experienced and qualified.
            Our primary goal is to make our roads safer and to minimize road accidents by human error.
            We offer quality driving training services all 7 days a week.
            We have an exceptional rate of success in road tests conducted by GA state DDS.
            Our instructory provide lessons in English and few other Indian languages.
          </div>
        </div>
        <br/>
        <br/>
        <div className="row">
            <div className="col-sm-12 gallery" >
            <h2 className='heading'>GALLERY</h2>
              <Gallery />
            </div>  
        </div>
        <br/>
        <br/>
        <br/>
      </div>
      </div >
    );
  }

}

export default AboutUs;
