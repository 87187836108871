export const services = [
  {
    courseName:'2/4/6/8/10 Hours Behind the wheel.',
    courseId:1,
    description:'2/4/6/8/10 hours Behind the wheel, each class is for 2 hours. With pick and drop facility',
    cost:"Call for Prices",
    category:'Online',
    categoryId:1
    },
  {
  courseName:'30 Hours Online & 6 Hours Behind the wheel.',
  courseId:1,
  description:'A 30-hour online driver’s education course approved by the the Georgia DDS. Satisfies course requirement for Joshua’s Law.',
  cost:"Call for Prices",
  category:'Online and Behind the wheel',
  categoryId:1
  },
  {
    courseName:'30 Hours Online & 10 Hours Behind the wheel  with Drivers License  assistance.',
    courseId:2,
    description:'A 30-hour online driver’s education course approved by the the Georgia DDS. Satisfies course requirement for Joshua’s Law.',
    cost:"Call for Prices",
    category:'Online and Behind the wheel',
    categoryId:1
  },
  {
    courseName:'30 Hours Online & 8 Hours Behind the wheel  with Drivers License  assistance.',
    courseId:3,
    description:'A 30-hour online driver’s education course approved by the the Georgia DDS. Satisfies course requirement for Joshua’s Law.',
    cost:"Call for Prices",
    category:'Online and Behind the wheel',
    categoryId:1
  },
  {
    courseName:'Drivers Licese assistance',
    courseId:4,
    description:'Present proof of driving expereice / behind the wheel training to schedule drivers test with us within 2 weeks with 2 hours behind the wheel on the test track to increase your confidence.', 
    cost:"Call for Prices",
    category:'Drivers Licese assistance',
    categoryId:2
  },

];
