import React,{Component} from 'react';
import { Alert } from 'reactstrap';

class FormErrors extends Component {

  constructor(props){
    super(props);
  }

  render(){
    const formErrors = this.props.formErrors;
    return(
      <div>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        return (
          <Alert color="danger" key={i}>
          <p key={i}> {formErrors[fieldName]}</p>
          </Alert>
        )
      } else {
        return '';
      }
    })}
    </div>
  );
  }

}

export default FormErrors;
