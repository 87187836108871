import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import '../stylesheets/contactUs.css';
import FormErrors from './FormErrorsComponent';
import ReCAPTCHA from "react-google-recaptcha";
import * as Constants from './Constants/constantData';

export default class ContactUs extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      emailId:'',
      phoneNumber:'',
      message:'',
      city:'',
      stateLoc:'',
      zipcode:'',
      captcha:'',
      isEmailValid:false,
      isPhoneNumberValid:false,
      isMessageValid:false,
      isCityValid:false,
      isStateLocValid:false,
      isZipcodeValid:false,
      isFormValid:false,
      isCaptchaValid:false,
      formErrors:{
          email:'',
          phoneNumber:'',
          message:'',
          city:'',
          stateLoc:'',
          zipcode:'',
          captcha:''
      }
    }
    this.baseState = this.state ;
    this.handleSubmit=this.handleSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }


 resetForm = () => {
  this.setState(this.baseState)
}

  onChange(value) {
    this.setState({
      captcha:value,
      isCaptchaValid:true
    },this.validateForm);
  }


  handleSubmit(event){
    event.preventDefault();
    if(this.state.captcha==''){
      const fieldErrors = this.state.formErrors;
      fieldErrors.captcha='Captcha is invalid'
      this.setState({
        formErrors:fieldErrors
      });
      return;
    }
    //contactSpinner

            // Add your logic to submit to your backend server here.
        document.getElementById("contactSpinner").style.display="block";
        fetch(Constants.URL+'/contactUS', {
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           body: JSON.stringify({
             
             emailId:this.state.emailId,
             phoneNumber:this.state.phoneNumber,
             stateLoc:this.state.stateLoc,
             city:this.state.city,
             zipcode:this.state.zipcode,
             message:this.state.message,
           })
         }).then(res => res.json())
         .then((result) => {
          document.getElementById("contactSpinner").style.display="none";
           if(result.status === 200) {
             alert('Email Sent');
             this.resetForm();
             }
           else {
             alert('Error Sending Email '+result.message);
             
           }
 
 
           },
         (error) => {
            document.getElementById("contactSpinner").style.display="none";
                 alert('Error Sending Email');
                 
         });
   
  }
  
  handleChange(event) {
    
    const item = event.target.name;
    const value = event.target.value;
    this.setState({[item]:event.target.value},
      () => { this.validateField(item, value)});
   //this.setState({value: event.target.value});
   
 }

 validateField(fieldName, value) {


  let fieldValidationErrors = this.state.formErrors;
  let emailValid = this.state.isEmailValid;
  let phoneNumberValid = this.state.isPhoneNumberValid;
  let cityValid = this.state.isCityValid;
  let stateLocValid = this.state.isStateLocValid;
  let zipcodeValid = this.state.isZipcodeValid;
  let messageValid = this.state.isMessageValid;

  switch(fieldName) {
    case 'emailId':
      if(value.length <=1){
        fieldValidationErrors.email = 'Please enter email id'
      }
      else {
        const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
              emailValid = regex.test(value);
      fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
      }
      break;
    case 'phoneNumber':
      const regexPhone=/((^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|[0-9]{10})/;
      phoneNumberValid = (value.replace(/-/g,"").length  === 10 && regexPhone.test(value)) ;
      fieldValidationErrors.phoneNumber = phoneNumberValid?'':'Enter a Valid Phone Number, 10 digits';
      break;
    case 'city':
      const regexCity = /^[a-zA-Z]+$/;
      cityValid = (value.length > 1 && regexCity.test(value));
        fieldValidationErrors.city = cityValid?'':'Please enter a valid city';
      break;
    case 'stateLoc':
      const regexState  = /^(GA|Georgia)$/i
      stateLocValid = (value.length >=2 && regexState.test(value));
        fieldValidationErrors.stateLoc = stateLocValid?'':'Please enter valid State, We operate only in GA';
      break;
    case  'zipcode':
      const regexZip = /^[0-9]{5}$/;
      zipcodeValid = (value.length === 5 && regexZip.test(value));
        fieldValidationErrors.zipcode = zipcodeValid?'':'Please enter valid zipcode';
      break;
    case 'message':
      messageValid  = value.length > 1
        fieldValidationErrors.message = messageValid?'':'Please enter valid Message';
      break;
    default:
      break;
  }
  this.setState({formErrors: fieldValidationErrors,
                  isEmailValid:emailValid,
                  isPhoneNumberValid:phoneNumberValid,
                  isCityValid:cityValid,
                  isStateLocValid:stateLocValid,
                  isZipcodeValid:zipcodeValid,
                  isMessageValid:messageValid,
                }, this.validateForm);
}

validateForm() {
this.setState({isFormValid: this.state.isEmailValid
              && this.state.isPhoneNumberValid
              && this.state.isCityValid
              && this.state.isStateLocValid
              && this.state.isZipcodeValid
              && this.state.isMessageValid
              && this.state.isCaptchaValid
            });
            
}

  render() {
    return (
      <div>
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="emailId">Email *:</Label>
              <Input type="email" name="emailId" id="emailId" value={this.state.emailId} onChange={this.handleChange} placeholder="email@domain.com" />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="phoneNumber">Phone Number *:</Label>
            <Input type="text" name="phoneNumber" id="phoneNumber" value={this.state.phoneNumber} onChange={this.handleChange} placeholder="111-222-3333"/>
          </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="city">City *:</Label>
              <Input type="text" name="city" id="city" value={this.state.city} onChange={this.handleChange}/>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="stateLoc">State *:</Label>
              <Input type="text" name="stateLoc" id="stateLoc" value={this.state.stateLoc} onChange={this.handleChange}/>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="zipcode">Zip *:</Label>
              <Input type="text" name="zipcode" id="zipcode" onChange={this.handleChange} value={this.state.zipcode}/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup row>
          <Label for="message" sm={2}>Message</Label>
          <Col sm={10}>
            <Input type="textarea" name="message" id="message" value={this.state.message} onChange={this.handleChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}></Col>
          <Col sm={10}>
            <ReCAPTCHA
              sitekey="6LfXMwUaAAAAAPZOCiJhvbWJdMDY6qhxPNmZh06_"
              onChange={this.onChange}
            />
            </Col>
        </FormGroup>
        <Alert color="info">Please enter all required fields to send message</Alert>
        <Button onClick={this.handleSubmit} disabled={!this.state.isFormValid} >Send Message</Button>
        <div id="contactSpinner" className="loader"></div>
      </Form>
      <FormErrors formErrors ={this.state.formErrors} />
      </div>
    );
  }

}
