import booked from './Images/booked.JPG';
import bookedImg from './Images/booked1.jpg';
import background from './Images/traffic.jpg';
import behindTheWheel from './Images/behindTheWheel.jpg';
import classRoom from './Images/classroom.jpg';
import reviews from './Images/reviews.png';
import logo from './Images/automobile.png';


export default {
  booked,bookedImg,logo,background,behindTheWheel,classRoom,
  reviews
}
