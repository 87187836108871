import React,{Component} from 'react';
import { Card, Button, CardHeader, CardFooter, CardBody, Alert,
  CardTitle, CardText } from 'reactstrap';
import {  Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import FormErrors from './FormErrorsComponent';
import { Redirect } from 'react-router-dom';
import * as Constants from './Constants/constantData';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";



class SignUp extends Component {

  constructor(props){
    super(props);
    this.state  = {
      firstName:'',
      lastName:'',
      dob:new Date(new Date().setFullYear(new Date().getFullYear() - 15)),
      age:0,
      emailId:'',
      phoneNumber:'',
      address:'',
      city:'',
      stateLoc:'',
      zipcode:'',
      password:'',
      llicense:'',
      parentName:'',
      parentEmail:'',
      parentMobile:'',
      licenseExpDate:  new Date(),
      confirmPassword:'',

      isFormValid:false,
      isEmailValid:false,
      isPasswordValid:false,
      isPhoneNumberValid:false,
      isConfirmPasswordValid:false,
      isFirstNameValid:false,
      isDobValid:false,
      isLastNameValid:false,
      isAddressValid:false,
      isCityValid:false,
      isStateLocValid:false,
      isZipcodeValid:false,
      isLlicenseValid:false,
      isLicenseExpDateValid:false,
      formErrors:{
        email:'',
        password:'',
        confirmPassword:'',
        address:'',
        firstName:'',
        lastName:'',
        phoneNumber:'',
        licenseExpDate: '',
        city:'',
        dob:'',
        stateLoc:'',
        zipcode:'',
        llicense:'',
        parentName:'',
        parentEmail:'',
        parentMobile:''
      }
    };
    this.baseState = this.state ;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this); 
  }

  validateField(fieldName, value) {

      /*
      address:'',
      city:'',
      stateLoc:'',
      zipcode:'',
      password:'',
      llicense:'',
      parentName:'',
      parentEmail:'',
      parentMobile:'',
      licenseExpDate:
      */

      let fieldValidationErrors = this.state.formErrors;
      let emailValid = this.state.isEmailValid;
      let passwordValid = this.state.isPasswordValid;
      let phoneNumberValid = this.state.isPhoneNumberValid;
      let confirmPasswordValid = this.state.isConfirmPasswordValid;
      let firstNameValid = this.state.isFirstNameValid;
      let lastNameValid = this.state.isLastNameValid;
      let addressValid = this.state.isAddressValid; 
      let cityValid = this.state.isCityValid;
      let stateLocValid = this.state.isStateLocValid;
      let zipcodeValid = this.state.isZipcodeValid;
      let llicenseValid = this.state.isLlicenseValid;
      let dobValid = this.state.isDobValid;
      let licenseExpDateValid = this.state.isLicenseExpDateValid;

      switch(fieldName) {
        case 'emailId':
          if(value.length <=1){
            fieldValidationErrors.email = 'Please enter email id'
          }
          else {
            const regexEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
            emailValid = regexEmail.test(value);
          fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
          }
          break;
        case 'password':
          passwordValid = value.length >= 6;
          fieldValidationErrors.password = passwordValid ? '': 'Password is too short';
          break;
        case 'phoneNumber':
          const regexPhone=/((^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|[0-9]{10})/;
          phoneNumberValid = (value.replace(/-/g,"").length  === 10 && regexPhone.test(value)) ;
          fieldValidationErrors.phoneNumber = phoneNumberValid?'':'Enter a Valid Phone Number';
          break;
        case'confirmPassword':
          confirmPasswordValid = value === this.state.password;
          fieldValidationErrors.confirmPassword = confirmPasswordValid?'':'Password and Confirm password must be same';
          break;
        case 'firstName':
          firstNameValid = value.length > 1
            fieldValidationErrors.firstName = firstNameValid?'':'Please enter your First Name';
          break;
        case 'address':
          addressValid = value.length > 1
            fieldValidationErrors.address = addressValid?'':'Please enter valid address';
          break;
        case 'city':
          cityValid = value.length > 1
            fieldValidationErrors.city = cityValid?'':'Please enter valid city';
          break;
        case 'stateLoc':
          const regexState = /^(GA|Georgia)$/i;
          stateLocValid = (value.length >=2 && regexState.test(value));
          
            fieldValidationErrors.stateLoc = stateLocValid?'':'Please enter valid State. We operate only in GA';
          break;
        case  'zipcode':
          const regexZip = /^[0-9]{5}$/;
          zipcodeValid = (value.length ==5 && regexZip.test(value));
            fieldValidationErrors.zipcode = zipcodeValid?'':'Please enter valid zipcode';
          break;
        case 'llicense':
            llicenseValid  = value.length > 1
            fieldValidationErrors.llicense = llicenseValid?'':'Please enter valid License ID';
          break;
        case 'licenseExpDate':
          licenseExpDateValid = value > new Date()
            fieldValidationErrors.licenseExpDate = licenseExpDateValid?'':'License expires soon. Please renew to schedule';
          break;

        case 'lastName':
          lastNameValid = value.length > 0
            fieldValidationErrors.lastName = lastNameValid?'':'Please enter your Last Name';
            break;
        case 'dob':
            dobValid = value <= new Date(new Date().setFullYear(new Date().getFullYear() - 15))
            fieldValidationErrors.dob=dobValid?'':'You must be at least 15 years of age';
        default:
          break;
      }
      this.setState({formErrors: fieldValidationErrors,
                      isEmailValid:emailValid,
                      isPasswordValid:passwordValid,
                      isPhoneNumberValid:phoneNumberValid,
                      isConfirmPasswordValid:confirmPasswordValid,
                      isFirstNameValid:firstNameValid,
                      isLastNameValid:lastNameValid,
                      isAddressValid:addressValid,
                      isCityValid:cityValid,
                      isStateLocValid:stateLocValid,
                      isZipcodeValid:zipcodeValid,
                      isLlicenseValid:llicenseValid,
                      isDobValid:dobValid,
                      isLicenseExpDateValid:licenseExpDateValid
                    }, this.validateForm);
  }

validateForm() {
  this.setState({isFormValid: this.state.isEmailValid
                  && this.state.isPasswordValid
                  && this.state.isPhoneNumberValid
                  && this.state.isConfirmPasswordValid
                  && this.state.isFirstNameValid
                  && this.state.isLastNameValid
                  && this.state.isAddressValid
                  && this.state.isCityValid
                  && this.state.isStateLocValid
                  && this.state.isZipcodeValid
                  && this.state.isLlicenseValid
                  && this.state.isLicenseExpDateValid
                  && this.state.dob
                });
}

handleDateChange = date => {
  this.setState({
    licenseExpDate: date
  },()=> { this.validateField('licenseExpDate', date)});
};

handleDobDateChange = date => {
  this.setState({
    dob: date
  },()=> { this.validateField('dob', date)});
};

handleChange(event) {
    
    const item = event.target.name;
    const value = event.target.value;
    this.setState({[item]:event.target.value},
      () => { this.validateField(item, value)});
   //this.setState({value: event.target.value});
   
 }

 resetForm = () => {
   this.setState(this.baseState)
 }

 /*
 {
"firstName":"Praveen",
"lastName":"HV",
"password":"praveen",
"emailId":"praveenmurthi7@gmail.com",
"phoneNumber":"7743220408",
"address":"126 Howell Pointe",
"state":"GA",
"city":"Duluth"
}
 */
 handleSubmit(event) {
   //alert('Your favorite flavor is: ' + this.state.value);
    //signupSpinner
    document.getElementById("signupSpinner").style.display="block";
   event.preventDefault();
   fetch(Constants.URL+'/signup', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            password:this.state.password,
            emailId:this.state.emailId,
            phoneNumber:this.state.phoneNumber,
            address:this.state.address,
            stateLoc:this.state.stateLoc,
            city:this.state.city,
            zipcode:this.state.zipcode,
            learnersLicenseNum:this.state.llicense,
            licenseExpDate:  this.state.licenseExpDate,
            age:this.state.age,
            dob:this.state.dob,
            parentName:this.state.parentName,
            parentEmail:this.state.parentEmail,
            parentMobileNum:this.state.parentMobile,
          })
        }).then(res => res.json())
        .then((result) => {
          document.getElementById("signupSpinner").style.display="none";
          if(result.status === 200) {
            alert('User Created. Please Login');
            this.resetForm();
            }
          else {
            alert('Error Creating account '+result.message);
            
          }


          },
        (error) => {
          document.getElementById("signupSpinner").style.display="none";
                alert('Error Creating account');
                
        });


 }


  render(){

    return(
      <div>
        <Card>
          <CardHeader>Sign Up</CardHeader>
          <CardBody>
          <CardTitle></CardTitle>
            <Form onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <FormGroup>
                      <Label for="emailId">Email-Id *:</Label>
                      <Input type="text" name="emailId" value={this.state.email}
                          onChange={this.handleChange} />

                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password *:</Label>
                      <Input type="password" name="password"
                      value={this.state.password} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmPassword">Confirm Password *:</Label>
                      <Input type="password" name="confirmPassword"
                      value={this.state.confirmPassword} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="firstName">First Name *:</Label>
                      <Input type="text" name="firstName"
                      value={this.state.firstName} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="lastName">Last Name *: </Label>
                      <Input type="text" name="lastName"
                      value={this.state.lastName} onChange={this.handleChange} />
                    </FormGroup>

                    <FormGroup>
                      <Label for="phoneNumber">Mobile Number *:</Label>
                      <Input type="text" name="phoneNumber"
                      value={this.state.phoneNumber} onChange={this.handleChange} />
                    </FormGroup>
                    <Label for="">If Student is under 18</Label>
                    <FormGroup>
                      <Label for="parentName">Parent/Guardian Name:</Label>
                      <Input type="text" name="parentName"
                      value={this.state.parentName} onChange={this.handleChange} />
                    </FormGroup>

                    <FormGroup>
                      <Label for="parentEmail">Parent/Guardian Email:</Label>
                      <Input type="text" name="parentEmail"
                      value={this.state.parentEmail} onChange={this.handleChange} />
                    </FormGroup>


                    <FormGroup>
                      <Label for="parentMobile">Parent/Guardian Cell:</Label>
                      <Input type="text" name="parentMobile"
                      value={this.state.parentMobile} onChange={this.handleChange} />
                    </FormGroup>

                  </div>
                  <div className="col-sm">
                    <FormGroup>
                      <Label for="address">Address *:</Label>
                      <Input type="text" name="address"
                      value={this.state.address} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="city">City *:</Label>
                      <Input type="text" name="city"
                      value={this.state.city} onChange={this.handleChange} className="halfInput"/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="state">State *:</Label>
                      <Input type="text" name="stateLoc"
                      value={this.state.stateLoc} onChange={this.handleChange} className="halfInput" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="zipcode">Zipcode *:</Label>
                      <Input type="text" name="zipcode"
                      value={this.state.zipcode} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="llicense">Learners License Number *:</Label>
                      <Input type="text" name="llicense"
                      value={this.state.llicense} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Learner's License Expiry Date *:</Label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        name="licenseExpDate"
                        selected={this.state.licenseExpDate}
                        onChange={this.handleDateChange}
                      />
                      
                    </FormGroup>
                    <FormGroup>
                      <Label>Date of Birth *:</Label>
                      <DatePicker
                        name="dob"
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.dob}
                        onChange={this.handleDobDateChange}
                      />
                    </FormGroup>
                  </div>
              </div>
              <Alert color="info">Please Enter All Required Fields to Sign Up</Alert>
              <Input type="submit" value="Submit" className="btn btn-primary" disabled={!this.state.isFormValid}/>
              <div id="signupSpinner" className="loader"></div>
              </div>
            </Form>
            <FormErrors formErrors ={this.state.formErrors}/>
          </CardBody>
        </Card>
      </div>

    );
  }

}


export default SignUp;
