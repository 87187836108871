import React,{Component} from 'react';
import {  Button,Alert } from 'reactstrap';
import { Card,  CardText, CardBody, CardLink,
    } from 'reactstrap';
import Cookies from 'universal-cookie';
import UserPayment from './UserPayments';
import * as Constants from './Constants/constantData';
import '../stylesheets/services.css'


class UserInfo extends Component {

  constructor(props){
    super(props);
    this.state = {
      key :'',
      stripePubKey:'',
      sessionId:'',
      stripe:'',
      products:'',
      expeditedDL:'300',
      isChecked:false,
      customAmount:'',
    };

    this.startPayment = this.startPayment.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.expeditedDLChanged = this.expeditedDLChanged.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.changeAmount=this.changeAmount.bind(this);
  }


  handleChangeChk(){

    this.setState({
      isChecked: !this.state.isChecked // flip boolean value
    });
  }

  componentDidMount(){
      const cookies = new Cookies();
    if(cookies.get('shivaDStoken')!==null||
          cookies.get('shivaDStoken')!==undefined) {
            this.setState({
              key:cookies.get('shivaDStoken')
            });
          }

    ///products
    fetch(Constants.URL+'/products', {
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'Bearer '+ this.state.key,
           },
         }).then(res => res.json())
         .then((result) => {
           if(result.status === 200) {
                this.setState({
                  products:result.data
                });
             }
             else {
               alert('Unable to fetch classes information')
             }
           }
           ,
         (error) => {

         });
  }


  static getDerivedStateFromProps(props, state) {
   
    if (props.authKey !== state.key) {
      return {
        key: props.authKey,

      };
    }
    else {
      const cookies = new Cookies();
     
      if(cookies.get('shivaDStoken')===null||
            cookies.get('shivaDStoken')===undefined) {
              return null;
            }
      else {
        return {
          key: cookies.get('shivaDStoken'),
        };
      }
    }
    
  }

  expeditedDLChanged(event){
   
    this.setState({
      expeditedDL:event.target.value
    });
  }

  changeAmount(event) {
    const value = event.target.value;
    const regexNum  = /^[0-9]+$/;
    if(regexNum.test(value))  {
      this.setState({
        customAmount:value
      });
    }
    if(value===''){
      this.setState({
        customAmount:value
      });
    }
  }

  startPayment(event){
    
    let payAmount = Number(event.target.innerHTML.substring(2));
    
    let obj = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ this.state.key,
          },
          body: JSON.stringify({
            'userName': this.state.email,
            'password': this.state.password,
            'amount':payAmount
          })};

    
    fetch(Constants.URL+'/stripeConfig',obj ).then(res => res.json())
         .then((result) => {
           if(result.status === 200) {
           
           const stripePubKey = result.data.publicKey;
           const stripeVar=  window.Stripe(result.data.publicKey);
           
           this.makePayment(stripePubKey,stripeVar,payAmount);
           //this.props.isAuthed(result.Authorization);
           }
           else {
             alert('Payment Setup was unsucessful');
           }
           },
         (error) => {
            alert("Error while getting payment information");
            
            return;
         });

  }

  makePayment(stripePubKey, stripeVar,payAmount){
    var obj = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ this.state.key,
          },
          body: JSON.stringify({
            'username': this.state.email,
            'password': this.state.password,
            'amount':payAmount
          })};

    fetch(Constants.URL+"/stripePayment",  obj ).then(res => res.json())
  .then((result) => {

    if(result.status === 200) {

    stripeVar
        .redirectToCheckout({
          sessionId: result.data.sessionId
        })
        .then((result)=>{
          
          if (result.error) {
          
          }
        });
        }
        else {
          alert('Stripe Payment not available');
        }
    },
  (error) => {
     alert("Error while getting payment information");
     
  });
  }

  render(){
    
    const customPay = (
        <Card>
          <CardBody>
                <CardText>Custom Pay ( Please enter the agreed amount during scheduling )</CardText>
                <CardText>
                  <label>Enter your amount</label>
                  <input type='text' value={this.state.customAmount} placeholder="0" onChange={this.changeAmount}/>
                  <Button color="primary" disabled={!this.state.isChecked} onClick ={this.startPayment}> ${this.state.customAmount} </Button>
                </CardText>
          </CardBody>
        </Card>
      );

    if(this.state.products != '')  {
    let key = 0;
    
    const  productsInfo = this.state.products.map((product)=> {
      if(product.userInfoReq === false ) {
      return(
      <div className="col-sm" key = {product.id} >
        <Card>
          <CardBody>
                <CardText>{product.description}</CardText>
                <CardText>
                  <Button color="primary" disabled={true} > Call for Prices </Button>
                </CardText>
          </CardBody>
        </Card>
      </div>
    )
    }
    else {
      return(
        <div>
          <br/>
        <div className="col-sm" key = {product.id} >
          <Card>
            <CardBody>
                  <CardText>{product.description}</CardText>
                  <CardText>
                  <div>
                  <label>
                    Name:
                    <input type="text" name="name" />
                  </label>
                    </div>
                    <div>
                  <label>
                    Do you have Learners License :
                    Yes<input type="radio" id="llicense" name="llicense" />
                    No<input type="radio" id="llicense" name="llicense" />
                  </label>
                  <span> (Have a copy of proof showing your driving hours.)</span>
                  </div>
                  <div>
                  <label>
                    How quickly do you want to schedule your test ?
                    <select id="expediteDL" onChange={this.expeditedDLChanged}>
                      <option value="400">within a week $300+$100</option>
                      <option value="375">two weeks from now $300+$75</option>
                      <option value="350">three weeks from now $300+$50</option>
                      <option value="300" selected>four weeks from now $300</option>
                    </select>
                  </label>
                  </div>
                  <div>
                    <Button color="primary" > "Call for Prices" </Button>
                  </div>
                  </CardText>
            </CardBody>
          </Card>
        </div>
        <br/>
        </div>
      );
    }
  }

 );

    return(
      <div>
        <br/>
        <div className="container">
          <div className="row">

          <Alert color="danger">
            1.Please call office to schedule/cancel and proceed with payment/refund.<br/>
            2.For Driver's Licese test request you will be contacted to confirm your availability.<br/>
            3.Refunds are accepted upto 24 hours before the scheduled class. <br/>
            <label>I agree to above terms</label>
            <input type="checkbox" checked={this.state.isChecked} onChange={this.handleChangeChk} />
         </Alert>
          </div>
          <div className="row">
              {productsInfo}
          </div>
          <div className="row">
              <br/>
          </div>

          <div className="row">
            {customPay}
          </div>

        </div>
        <br/>
        <Alert color="warning">
          We dont store card info of any customers. All payments are handled by stripe,
          we confirm payment based on stripe information.
          Please read about stripe payments for more details.
       </Alert>
        <br/>
        <div className="container bg-color-blue">
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h3>User Payment History</h3>
          </div>
        </div>
          <div className="row justify-content-center">
          <div className="col-sm-12">
          <UserPayment/>
          </div>
          </div>
        </div>
        <br/>
        <br/>
      </div>
    );
    }
    else {
      {
        return(
          <div>
          Loading Classes Information..
          </div>
        )
      }
    }
  }

}

export default UserInfo;
