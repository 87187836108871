import React,{Component} from 'react';
import LoginOrSignUp from './LoginOrSignupComponent.js';
import CarSVG from './ImageConstants/Images/3629703.jpg';

class Authenticate extends Component {
  constructor(props){
    super(props);
  }

  render(){
    console.log("auth component");
    console.log(this.props.loginAction);

    //if(this.props.authKey  == null){
    return(
      <div className="row w-100">
        <div className="col-sm-6">
          <br/>
          <h3>Create an Account to track your schedules & </h3>
          <h3>Pay Online for your next appointment </h3>
          {/*<img src={CarSVG} alt="Car Bg Picture" width="700px" height="500px"/>*/}
        </div>
        <div className="col-sm-6">
          <br/>
          <LoginOrSignUp loginAuthAction = {this.props.loginAction } />
          <br/>
        </div>
      </div>);
    //  }
  //    else {
  //      return (<Redirect to='/userInfoPage' />);
  //    }

  }

}

export default Authenticate;
