import React,{Component} from 'react';

import newHeader from './ImageConstants/newHeader.js';
import '../stylesheets/bgheader.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,Badge,Button } from 'reactstrap';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import {Link} from 'react-router-dom';

class NewHeader extends Component {

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
          collapsed: true,
          token:'',
          isOpen:true,
        };
      }
    
    
    
      toggleNavbar() {
        this.setState({
          collapsed: !this.state.collapsed
        });
      }
    


    render(){
        return (
                <div className="container-fluid w-100 newHeaderBg">
                    <div className="row">
                        <div className="col-sm-1">

                        </div>
                        <div className="col-sm-10">
                            <div className="headTitle">Shiva Driving School</div>
                            <div className="tagLine">Experience and Safety Matters</div>
                            <h3><Badge color="primary">DT-648</Badge></h3>
                            <h3><Badge color="success"><i className="fa fa-phone fa-lg"></i> Cell: 404-980-2197</Badge>
                            <Badge color="danger"><i className="fa fa-phone fa-lg"></i> Office: 678-878-3333</Badge></h3>
                        </div>
                        <div className="col-sm-1">

                        </div>
                    </div>
                    <div className="row">
                          <div className="col-sm-12"><br/></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1"></div>
                      <div className="col-sm-4">
                        <Toast className="toast-color">
                          <ToastHeader>
                            Current Operation
                          </ToastHeader>
                          <ToastBody>
                            Opened on all days of the week from 8:00 to 20:00.<br/>
                            Local Door-to-Door Pick-Up for Driving Lessons.<br/>
                            One student per car.<br/>
                            Next Available Class: Please Call.
                          </ToastBody>
                        </Toast>
                      </div>
                      <div className="col-sm-2">
                        <Link to="/static/auth/SignIn" ><Button color="warning" size="lg">Register Today</Button></Link>
                      </div>
                    </div>
                </div>
        );
    }

}

export default NewHeader;