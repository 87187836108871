import React,{Component} from 'react';
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import {  Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import FormErrors from './FormErrorsComponent';
import * as Constants from './Constants/constantData';

class ForgotPassword extends Component {

    constructor(props){
      super(props);
      this.state = {
        emailId:'',
        token:'',
        password:'',
        confirmPassword:'',
        passSetError:'',
        isFormValid:false,
        isEmailValid:false,
        isPasswordValid:false,
        isTokenValid:false,
        isConfirmPasswordValid:false,
        formErrors:{
            email:'',
            password:'',
            token:'',
            confirmPassword:'',
        }
      }
      this.baseState = this.state ;
      this.handleChange = this.handleChange.bind(this);
      this.generateToken=this.generateToken.bind(this);
      this.resetPassword = this.resetPassword.bind(this);
    }

    resetForm = () => {
      this.setState(this.baseState)
    }
    
    validateField(fieldName, value) {

     
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.isEmailValid;
        let passwordValid = this.state.isPasswordValid;
        let tokenValid = this.state.isTokenValid;
        let confirmPasswordValid = this.state.isConfirmPasswordValid;

        switch(fieldName) {
            case 'emailId':
              if(value.length <=1){
                fieldValidationErrors.email = 'Please enter email id'
              }
              else {
                const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
              emailValid = regex.test(value);
              fieldValidationErrors.email = emailValid ? '' : ' is invalid';
              }
              break;
            case 'password':
              passwordValid = value.length >= 6;
              fieldValidationErrors.password = passwordValid ? '': ' is too short';
              break;
            case 'token':
              tokenValid = value.length == 15;
              fieldValidationErrors.token = tokenValid ? '': ' is too short';
              break;
            case'confirmPassword':
              confirmPasswordValid = value === this.state.password;
              fieldValidationErrors.confirmPassword = confirmPasswordValid?'':'Password and Confirm password must be same';
              break;
            default:break;
        }
        this.setState({formErrors: fieldValidationErrors,
            isEmailValid:emailValid,
            isPasswordValid:passwordValid,
            isConfirmPasswordValid:confirmPasswordValid,
        }, this.validateForm);

    }
  
  
    handleChange(event) {
      
      const item = event.target.name;
      const value = event.target.value;
      this.setState({[item]:event.target.value},
        () => { this.validateField(item, value)});
      
   }

   validateForm() {
    this.setState({isFormValid: this.state.isEmailValid
                    && this.state.isPasswordValid
                    && this.state.isConfirmPasswordValid
                    && this.state.token
  
                  });
  }
   



    generateToken(event) {
        
        document.getElementById("Forgotspinner").style.display="block";
        event.preventDefault();
        
        fetch(Constants.URL+'/generateToken', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: this.state.emailId,
            })
            }).then(res => res.json())
            .then((result) => {
              document.getElementById("Forgotspinner").style.display="none";
            if(result.status === 200) {
    
                alert("Token Generated Successfully");
                this.setState({
                  passSetError:'',
                  isPassSetError:false,
              });
                }
                else {
                this.setState({
                    passSetError:'Error: Please check your email',
                    isPassSetError:true,
                });
                }
            }
            ,
            (error) => {
              document.getElementById("Forgotspinner").style.display="none";
                alert("Generate  Token Failed");
            });
    }

    resetPassword(event){

        if(this.state.password != this.state.confirmPassword){

        }
        //resetSpinner
        document.getElementById("resetSpinner").style.display="block";
        event.preventDefault();
        fetch(Constants.URL+'/resetPassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: this.state.emailId,
                token:this.state.token,
                password:this.state.password,
            })
            }).then(res => res.json())
            .then((result) => {
              document.getElementById("resetSpinner").style.display="none";
            if(result.status === 200) {
              
                alert("Password Changed Successfully");
                this.resetForm();
                }
                else {
                this.setState({
                    passSetError:'Error: Please check your email and validation token',
                    isPassSetError:true,
                });
                }
            }
            ,
            (error) => {
              document.getElementById("resetSpinner").style.display="none";
                alert("Password Reset Failed");
            });

    }
 
   render(){
    return(
    <div>
        <Card>
          <CardHeader>Forgot Password</CardHeader>
          <CardBody>
          <CardTitle>
            {this.state.isPassSetError?this.state.passSetError:''}
          </CardTitle>
            <Form onSubmit={this.resetPassword}>
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <FormGroup>
                      <Label for="emailId">Email-Id *:</Label>
                      <Input type="text" name="emailId" value={this.state.emailId}
                          onChange={this.handleChange} />
                      <p>This Email Id should be the one you used during Sign-up</p>
                       <Button variant="info" disabled={!this.state.isEmailValid}  onClick={this.generateToken}>Generate Token</Button>
                       <div id="Forgotspinner" className="loader"></div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="token">Token *:</Label>
                      <Input type="text" name="token" value={this.state.token}
                          onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password *:</Label>
                      <Input type="password" name="password"
                      value={this.state.password} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmPassword">Confirm Password *:</Label>
                      <Input type="password" name="confirmPassword"
                      value={this.state.confirmPassword} onChange={this.handleChange} />

                    </FormGroup>
                    </div>
              </div>
              <Input type="submit" value="Reset Password" disabled={!this.state.isFormValid} className="btn btn-primary" />
              <div id="resetSpinner" className="loader"></div>
              </div>
            </Form>
            <FormErrors formErrors ={this.state.formErrors}/>
          </CardBody>
        </Card>
    </div>
    );

   }
}

export default  ForgotPassword;