import React, {Component} from 'react';
import Presentation from './PresentationComponent';
import BasicContent from './BasicContentComponent.js';
import { Progress, Card} from 'reactstrap';
import '../stylesheets/home.css';
import ContactUs from './ContactUsComponent.js';

class Home extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <br/>
        <br/>
        <div className="row">
          <div className='col-sm-1'></div>
          <div className='col-sm-10'>
            <BasicContent/>
          </div>
          <div className='col-sm-1'></div>
        </div>
        <br/>
        <br/>
        <br/>
        <div className="row seperator">
          <div className="w-100"></div>
          </div>
            <div className="row background-about-us">
              <div className='col-sm-6 left-align'>
                <h2>Why Choose Us</h2>
                <div className="about-us">
                We are locally owned, operated and legally licensed by state of Georgia Department of drivers  services.
                Best rated driving school on google and yelp serving metro atlanta region.
                Professional and strategical methodology of teaching to the first-timers as well as to the experienced drivers.
                Our instructors provide lessons in English and few other Indian languages.
                We provide competitive rates. Open 7 days a week to meet your busy schedule.
                We also consider other driving school students to help them get driving license.
                
                </div>
              </div>
              <div className='col-sm-6'>
                <Presentation height="500px"/>
              </div>
            </div>
            <br/>
            <br/>
            <div className="row seperator">
              <div className="w-100"></div>
            </div>
            <div className="row">
              <div className="col-sm-1"/>
              <div className="col-sm-10">
                <h2>Customer Ratings and Statistics</h2>
                <div className="row">
                  <div className="col-sm-6">
                    <h4>Ratings</h4>
                    <div className="text-left">Customer Rating 5 of 5</div>
                    <Progress animated color="success" value="100"/>

                    <div className="text-left">Pass Rate On First test 96 of 100</div>
                    <Progress animated color="danger" value="96"/>
                    <br/>
                    <br/>
                    <div className="row" >
                      <div className="mx-auto">
                        <h4>Reviews from the web</h4>
                      </div>
                    </div>
                    <br/>
                    <div className="row">
                      <div className="col-sm-4">
                        <a href="https://getpassed.net/practices/united-states/georgia/lawrenceville/shiva-driving-school-6645">GetPassed</a>
                        <h5>5/5</h5>
                      </div>
                      <div className="col-sm-4">
                        <a href="http://localservices.sulekha.com/shiva-driving-school-llc_lawrenceville-ga_762698">Sulekha Local Services</a>
                        <h5>5/5</h5>
                      </div>
                      <div className="col-sm-4">
                        <a href="https://www.google.com/search?q=shiva+driving+school">Google</a>
                        <h5>4.9/5</h5>
                      </div>

                    </div>
                  

                  </div>
                  <div className="col-sm-6 review">
                    <h3>Reviews</h3>
                    <div className="reviews-bg">
                    <div className='comment-section'>
                      <div className="comment-text">
                        Paul is the best. I just moved to Georgia from new york, never driven in my life before and I did 6 lessons with paul, and he took the fear I had of driving out of me on the first lesson!. I did my lessons in late june, early july and and a gap where I didnt drive until 2 days before my road test. The day of the road test he took me about, helping me, reassuring me that I can go it, he was like a father giving a pep talk, lol, and I passed on the first try! I sing paul praises, and would recommend him to any everyone!.... Thanks Paul
                      </div>
                      <div className="comment-footer">
                        ---kay Walker
                      </div>
                    </div>
                    <br/>
                    <div className='comment-section'>
                      <div className="comment-text">
                        On my first day driving with Paul , I was comfortable, he helped me understand the rules of the road and how to be more comfortable with my turning and driving on the highway. He’s a very detailed driving instructor. The classes aren’t too pricey and you get A bang for every buck. It took me only three 2 hour classes with him to get my license! Thank you Paul!!
                      </div>
                      <div className="comment-footer">
                        ---Jovanna Laurencin
                      </div>
                    </div>
                    <br/>
                    <div className='comment-section'>
                      <div className="comment-text">
                      Paul is Very good Instructor, he will assess the Learners skills first, then he will improve their skills in lacking area.
                      Experience is feather in his crown and professionalism, gudience will help commuters to get Licence as early as possible.
                      </div>
                      <div className="comment-footer">
                        ---Manoj Reddy
                      </div>
                    </div>
                    <br/>
                    <div className='comment-section'>
                      <div className="comment-text">
                        I took 6 hour classes with Paul. His training is so intense that you'll lose the road fear in the first class itself. 
                        I've passed my driving test with full points on the first attempt itself. His techniques are so easy and understandable. (Especially parallel parking) Also, the best thing is Shiva driving school is affordable. I would recommend him to everyone.
                      </div>
                      <div className="comment-footer">
                        ---Meenakshi Nemani
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-1"/>
            </div>
            <br/>
            <br/>
            <div className="row seperator">
              <div className="w-100"></div>
              </div>
                <div className="row">
                  <div className="container-fluid  background-green">
                    <div className="row w-100">
                      <div className="col-sm-12">
                        <h2 className="contact-color">Contact Us</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className='col-sm-4'>
                      <a href="https://www.guidetogwinnett.com/united-states/lawrenceville/college-specialized-training/shiva-driving-school?from=badge"title="Find me on Guide To Gwinnett" ><img height='350' width='350' src="https://www.guidetogwinnett.com/images/BOG-Vote-250x250.jpg" alt="BOG-Vote"/></a>
                      </div>
                      <div className='col-sm-4'>
                        <ContactUs/>
                      </div>
                      <div className='col-sm-4'/>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <br/>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            );
  }

}

export default Home;
