import React,{Component} from 'react';
import * as courses from './Constants/servicesData.js';
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, CardText } from 'reactstrap';
  import { Badge } from 'reactstrap';
import { Alert } from 'reactstrap';
import '../stylesheets/services.css';

class Services extends Component {

  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount(){
    window.scrollTo(0, this.myRef.current.offsetTop);   
  }

  render(){
    const coursesData = courses.services.map((course) => {
      return(
      <div className="col-sm-4">
      <Card key = {course.id}  className="card-margin-top">
       <CardHeader>{course.courseName}</CardHeader>
       <CardBody>
         <CardTitle>{course.category}</CardTitle>
         <CardText>{course.description}</CardText>
         <Badge color="danger">${course.cost}</Badge>
       </CardBody>
     </Card>
     </div>

      )
    });

    return(
      <div ref={this.myRef} className="container">
      <br/>
      <Alert color="primary">
       Please login to schedule and make online payment
     </Alert>

        <div className="row">
          {coursesData}
        </div>
        <br/>
        <br/>
      </div>
    );
  }

}

export default Services;
