import React,{Component} from 'react';
import {  Button } from 'reactstrap';
import Cookies from 'universal-cookie';
import { Table } from 'reactstrap';
import * as Constants from './Constants/constantData';
import '../stylesheets/services.css'

class UserPayment extends Component {
  constructor(props)
  {
    super(props);
    this.state ={
      paymentHistory:'',
      paymentError:'',
      isAdmin:false
    };

    this.requestRefund = this.requestRefund.bind(this);

  }

  componentDidMount(){

      const cookies = new Cookies();
      let userKey = ''
      if(cookies.get('shivaDStoken')!==null||
            cookies.get('shivaDStoken')!==undefined) {

                userKey = cookies.get('shivaDStoken');

            }

      fetch(Constants.URL+'/userPayments', {
           method: 'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization':'Bearer '+ userKey,
           },
         }).then(res => res.json())
         .then((result) => {
           console.log(result);
           if(result.status === 200) {
             
                this.setState({
                  paymentHistory:result.data,
                  isAdmin: result.admin
                });
             }
             else {
               this.setState({
                 paymentError:'Unable to fetch Payment information'
               });
             }
           }
           ,
         (error) => {
           this.setState({
             paymentError:'Unable to fetch Payment information'
           });
         });
       }

     requestRefund(event,refundReq) {
      document.getElementById("refundSpinner").style.display="block";

       if(refundReq.refundRequested ===true){
         event.preventDefault();
         return;
       }

       const cookies = new Cookies();
       let userKey = ''
       if(cookies.get('shivaDStoken')!==null||
             cookies.get('shivaDStoken')!==undefined) {

                 userKey = cookies.get('shivaDStoken');

             }

       fetch(Constants.URL+'/initiateRefund', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ userKey,
              },
              body: JSON.stringify({
                userId: refundReq.userId,
                amount: refundReq.amount,
                sessionId:refundReq.sessionId
              })
            }).then(res => res.json())
            .then((result) => {
              document.getElementById("refundSpinner").style.display="none";
              if(result.status === 200) {
                let historyBkp = this.state.paymentHistory;
                let currentRow = historyBkp.find(({ sessionId }) => sessionId === refundReq.sessionId);
                currentRow.refundRequested =    result.data.refundRequested;

                this.setState({
                  paymentHistory:historyBkp
                });

              }
            }
            ,
          (error) => {
            document.getElementById("refundSpinner").style.display="none";
            this.setState({
              paymentError:'Unable to Refund information'
            });
          });

     }
     //onClick ={this.requestRefund(history)}
     //onClick={(evt) => this.handleRemove(id, evt)}
  render(){
    console.log(this.state);
  if(this.state.paymentHistory !== '' && this.state.isAdmin === false)  {
      const  userHistory = this.state.paymentHistory.map((history)=> {
        return(
        <tr key = {history.sessionId}>
          <td>{history.userId}</td>
          <td>{history.amount}</td>
          <td>{history.creationDate}</td>
          <td>{history.customerEmail}</td>
          <td> <a target="_blank" href={history.receiptUrl}> Receipt</a> </td>
          <td><Button onClick={(event) => this.requestRefund(event,history)} >Request Refund</Button></td>
          <td>{history.refundRequested?'Refund Requested':'Initiate Refund'}</td>
        </tr>
        )
      },this);
      return(
        <div>
           <div id="refundSpinner" className="loader"></div>
          <Table bordered hover className="bg-table-blue">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Amount $</th>
              <th>Creation Date</th>
              <th>Customer Email</th>
              <th>Receipt URL</th>
              <th>Request Refund</th>
              <th>Refund Requested</th>
            </tr>
            </thead>
            <tbody>
            {userHistory}
            </tbody>
          </Table>
        </div>
      );
    }
    else if(this.state.paymentHistory !== '' && this.state.isAdmin === true) {
      const  userHistory = this.state.paymentHistory.map((history)=> {
        return(
        <tr key = {history.sessionId}>
          <td>{history.userDetails.userId}</td>
          <td>{history.userDetails.firstName}</td>
          <td>{history.userDetails.lastName}</td>
          <td>{history.amount}</td>
          <td>{history.creationDate}</td>
          <td>{history.userDetails.address}</td>
          <td>{history.userDetails.phone}</td>
        </tr>
        )
      },this);
      return(
        <div>
           <div id="refundSpinner" className="loader"></div>
          <Table bordered hover className="bg-table-blue">
          <thead>
            <tr>
              <th>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Amount $</th>
              <th>Creation Date</th>
              <th>Address</th>
              <th>Phone</th>
            </tr>
            </thead>
            <tbody>
            {userHistory}
            </tbody>
          </Table>
        </div>
      );
    }
    else {
      return(
        <div> No Payment History Available </div>
      );
    }
  }

}

export default UserPayment;
