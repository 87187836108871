import React, {Component} from 'react';
import '../stylesheets/blackBar.css';
class BlackBar extends Component {

  render() {
    return (
      <div className="blackbg w-100">
  	<div className="row center-text">
  		<div className="col-sm-9 bg-brown">
    		<br/>
    		<h1>
      		<span className="text-color-1">SHIVA
      		</span>
      		<span className="text-color-1"> DRIVING
      		</span>
      		<span className="text-color-1"> SCHOOL <span className="small-text">LLC. </span> </span>
    		</h1>
			<span className="text-color-white">LICENSED BY THE STATE OF GEORGIA DEPARTMENT OF DRIVER SERVICES</span>
				<div className="container">
					<div className="row">
						<div className="col-sm-2">
						</div>
						<div className="col-sm-4 align-right">
						<h3>
						<span className="text-color-yellow font-big " >
						<i className="fa fa-mobile font-big" aria-hidden="true"></i> Cell: 404-980-2197
						</span>
						</h3>

						</div>
						<div className="col-sm-4 align-left">
							<h3>
							<span className="text-color-yellow font-big " >
							<i className="fa fa-mobile font-big" aria-hidden="true"></i> Office: 678-878-3333
							</span>
							</h3>
						</div>

					</div>
				</div>

  		</div>
		  <div className="col-sm-3 bg-brown">
			<h2  className="left-align">  <span className="text-color-red">Experience</span> <span className="text-color-white">and</span> </h2><h2 className="right-align"> <span className="text-color-yellow">Safety Matters</span> </h2>
		  </div>

  	</div>
	  <br/>
  	<div className="row w-100 image-row">

  		<div className="col-sm-9">
        <div className="row sub-text-orange">
          <div className="col-sm-12 text-black align-center">
            <h3>About Us </h3>
          </div>
        </div>
  			<div className="row sub-text">
  				<div className="col-sm-12 text-black">
  					<h5> <span className="font-size-bigger">Welcome</span> to <span className="text-color-orange"> Shiva Driving School </span>in Atlanta is a trusted and tested name for all your driving needs to be a safe and skilled driver</h5>
  				</div>
        </div>
        <div className="row sub-text">
  				<div className="col-sm-12 text-black">
  					<h5> We are providing quality driving lessons in <span className="text-color-2">GWINNETT</span>,  <span className="text-color-3">DEKALB</span>,  <span className="text-color-4">COBB</span>
					 ,  <span className="text-color-5">FULTON</span>,  <span className="text-color-6">FORSYTH</span> county and many more citites of <span className="text-color-7">METRO ATLANTA</span></h5>
  				</div>
  			</div>
  			<div className="row  sub-text">
  				<div className="col-sm-12 text-black">
  					<h5> Our unique approach helps develop proper attitude towards safe driving. Our programs include 30 - hours of online lectures and atleast 6 hours of in-car instructions and driving observations for teenagers as per Georgia Joshua's law.</h5>
  				</div>
        </div>
        <div className="row sub-text">
  				<div className="col-sm-12 text-black">
  					<h5><span className="text-color-6">Our Courses Designed to learn & drive with confidence.</span></h5>
  				</div>
  			</div>
  			<div className="row  sub-text">
  				<div className="col-sm-12  text-black">
  					<h5> We have custom tailored programs also just to meet your requirements to get Georgia Driving License. Individual driving school lessons are offered in 2 hours minimum block of time. We also consider other driving school students to help them get driving license</h5>
  				</div>
        </div>
        <div className="row sub-text">
  				<div className="col-sm-12 text-black">
  					<h5></h5>
  				</div>
  		</div>
        <div className="row sub-text-green">

  		</div>
        <div className="row sub-text">
  				
  		</div>
        <div className="row sub-text">
  				
  		</div>
  		</div>

  		<div className="col-sm-3 image-bg">
  		</div>
  	</div>
  </div>);
  }

}

export default BlackBar;
