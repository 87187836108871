import React,{Component} from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Card,CardHeader} from 'reactstrap';
import ContactUsComponent from './ContactUsComponent';
import '../stylesheets/aboutus.css';

class ContactUSMain extends  Component{

  constructor(props){
    super(props);
    this.myRef  =  React.createRef();
  }

  componentDidMount(){
    window.scrollTo(0, this.myRef.current.offsetTop);   
  }

render(){
  return (
    <Container >
    <br/>
      <br/>
      <Row >
      <br/>
        <br/>
          <br/>
        <Col md="8">
          <div className="contactUS contact-us-page">
          <h2 className="heading">Contact Us</h2>
          <ContactUsComponent/>
          </div>
        </Col>
        <Col md="4" className="contact-us-page">
        <h2 className="heading">Address</h2>
        <div>
          Lawrenceville,<br/> GA
          Zip Code - 30046<br/>
          Tel : +1 404-980-2197
          </div>
        </Col>
      </Row>
      <br/>
      <br/>
      <div  ref={this.myRef}></div>
    </Container>
  );
}

}
export default ContactUSMain;
