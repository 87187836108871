import React,{Component} from 'react';
import NavComponent from './navbarComponent.js';
import Home from './HomeComponent.js';
import '../stylesheets/mainComp.css';
import Footer from './FooterComponent.js';
import BlackBar from  './BlackBarComponent.js';
import {Switch,Route,Redirect} from 'react-router-dom';
import Services from './ServicesComponent.js';
import Auth from './AuthComponent.js';
import UserInfo from './UserInfoComponent.js';
import Logoff from './LogoffComponent.js';
import AboutUs from './AboutusComponent.js';
import ContactUSMain from './ContactUSMainComponent.js';
import NewHeader from './ImageHeaderComponent.js';

class MainComponent extends  Component{
  constructor(props) {
    super(props);this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      loginAuthKey:''
    };

    this.setLoginAuthKey = this.setLoginAuthKey.bind(this);

  }

  setLoginAuthKey(authKey){
    this.setState({
      loginAuthKey: authKey
    });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }


  render(){


    return(
    <div className="container-fluid">

      <div className="row navbar-bg">
        <div className='col-sm-1'>
        </div>
        <div className ='col-sm-10'>
          <NavComponent/>
        </div>
        <div className='col-sm-1'>
        </div>
      </div>
     
      <div className="row">
          <NewHeader/>
      </div>
      <Switch>
          <Route exact path="/"  render={(props) => <Home {...props} />} />
          <Route path="/static/services" component={Services} />
          <Route path="/static/logoff" component ={Logoff}  />
          <Route path="/static/auth"
           render={(props) => <Auth {...props} loginAction={this.setLoginAuthKey} authKey={this.state.loginAuthKey}/>} />
          <Route path="/static/userInfo"
          render={(props) => <UserInfo {...props} authKey={this.state.loginAuthKey}/>} />
          <Route path="/static/aboutus" component={AboutUs} />
          //ContactUSMain
          <Route path="/static/contactUSM" component={ContactUSMain} />
          <Redirect to = "/" />
      </Switch>
      <div className="row seperator" > <div className="w-100"> </div> </div>
        <div className="row">
            <Footer/>
        </div>

      </div>
    
  );
  }
}

export default MainComponent;
