import React,{Component} from 'react';
import { Card, CardImg, CardText, CardBody, CardLink,
  CardTitle, CardSubtitle,CardHeader,Button } from 'reactstrap';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { Media } from 'reactstrap';
import '../stylesheets/basicContent.css';
import { withRouter } from 'react-router-dom';

class BasicContent extends Component  {

  constructor(props){
    super(props);
    this.state = {
      message:'',
      messageHeader:'',
      display:false,
    }

    this.displayMessage = this.displayMessage.bind(this);
    this.registerNow=this.registerNow.bind(this);
  }

  registerNow(event){
    console.log(this.props);
    const { history } = this.props;
    if(history) history.push('/static/auth/SignIn');
  }

  displayMessage(event,type) {
    
    switch(type) {
      case 'behindTheWheel': 
      this.setState({
        messageHeader:'Behind the Wheel Info',
        message:"We provide 2 Hours behind the wheel private training  which are structured to meet the individual needs of the student. "+
                "Our Instructors constantly analyze each students progress and make adjustments to make the student confident and a better driver "+
        "Instructors are fully state licensed and trained to work with all drivers regardless of their prior driving experience. "+
          "Click register now and start your training",
        display:true,
        mediaImage:'https://img.icons8.com/plasticine/100/000000/car.png'
      });
      break;
      case 'driverEduCourse':
        this.setState({
        messageHeader:'Online Drivers Education Course',
        message:"Joshua's Law is a Georgia state law enacted in 2007 changing the driver's license requirements for teen drivers. A teen driver must meet the new requirements to obtain a Georgia driver's license "+
                "We provide 30 hours online training and 6 hours behind the wheel to make the student eligible to obtain the license. Click register now and start your training",
        display:true,
        mediaImage:'https://img.icons8.com/ios/100/000000/happy-cloud.png'
      });
      break;
      case 'roadTest':
        this.setState({
          messageHeader:'Final Road Test',
          message:"Georgia Road Test tests the ability of a candidate based on various parameters. This takes about 20-35 minutes. "+
          "This package includes 2 hours behind the wheel training which includes the actual test track with parallel parking training "+
          "Pick-up and drop-off from a place of your choice. Documents related to the car is provided with the car which is used in our "+
          "training to boost your confidence. We also help you expedite road test appointment.  Let's get started today! Click register now!",
          display:true,
          mediaImage:'https://img.icons8.com/officel/80/000000/driver.png'
        });
        break;


    }
    
    
  } 

  hideMessage(event) {
    this.setState({
      messageHeader:'',
      message:'',
      display:false
    });
  }


  render(){

    const toastShow = ( ) => {
      if(this.state.display) { 
      return (<Media>
        <Media left href="#">
          <Media object src={this.state.mediaImage} alt="Generic placeholder image" />
        </Media>
        <Media body>
          <Media heading>
            {this.state.messageHeader}
          </Media>
            {this.state.message}
            <br/>
            <br/>
            <Button onClick={(event) => this.hideMessage(event)} color="info">Hide</Button>
        </Media>
      </Media>)
            }
            else return('') } ;
    

    return(
      <div>
        <div className="row">
          <div className="col-sm-4">
          <Card className='card-shadow'>
            <div className="w-100">
              <CardImg top width="50%" src="https://img.icons8.com/plasticine/100/000000/car.png" alt="Card image cap" />
            </div>
            <CardBody>
              <CardTitle><h3>Behind the Wheel Lessons</h3></CardTitle>
              <CardSubtitle></CardSubtitle>
              <CardText>1-on-1 Behind the wheel instruction. Fully licensed
                instructors. Student pick-up/drop off included.</CardText>
              <Button color="success" onClick={this.registerNow}>Register Now</Button>
              <span>  </span>
              <Button onClick={(event) => this.displayMessage(event,'behindTheWheel')} color="danger">Learn More</Button>
            </CardBody>
          </Card>

          </div>
          <div className="col-sm-4">
          <Card className='card-shadow'>
            <div className="w-100">
              <CardImg top width="100%" src="https://img.icons8.com/ios/100/000000/happy-cloud.png" alt="Card image cap" />
            </div>
            <CardBody>
              <CardTitle><h3>Online Drivers Education Course</h3></CardTitle>
              <CardSubtitle></CardSubtitle>
              <CardText>Complete at your own pace & convenience. DDS approved.
                Meets Joshua’s Law requirement.</CardText>
                <Button color="success" >Coming Soon</Button>
                <span>  </span>
              <Button onClick={(event) => this.displayMessage(event,'driverEduCourse')} color="danger">Learn More</Button>
            </CardBody>
          </Card>

          </div>
          <div className="col-sm-4">
          <Card className='card-shadow'>
            <div className="w-100">
              <CardImg top src="https://img.icons8.com/officel/80/000000/driver.png" alt="Card image cap" />
            </div>
            <CardBody>
              <CardTitle><h3>Road Test</h3></CardTitle>
              <CardSubtitle></CardSubtitle>
              <CardText>Refresher course before Road Test on actual test track.
                This package includes free pick and drop along with reduced wait time for getting a test appointment.
              </CardText>
              <Button color="success" onClick={this.registerNow}>Register Now</Button>
              <span>  </span>
              <Button onClick={(event) => this.displayMessage(event,'roadTest')} color="danger">Learn More</Button>
            </CardBody>
          </Card>

          </div>
    </div>
    <br/>
    <br/>
    <div className="row">
      <div className="col-sm-12">
      {toastShow()}
      </div>
    </div>
    </div>
  );
  }

}

export default  withRouter(BasicContent);
