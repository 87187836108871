import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import slides from '../ImageConstants/GalleryConst.js';

const items = [
  {
    src: slides.slide1,
    altText: 'Note:',
    footer:'Step 1',
    caption: 'Schedule an appointment within 2/3 Weeks'
  },
  {
    src: slides.slide2,
    altText: 'Note:',
    footer:'Step 2',
    caption: 'Pick you up from your  preferred location  with a smile'
  },
  {
    src: slides.slide3,
    altText: 'Note:',
    footer:'Step 3',
    caption: 'Provide car and insurance'
  },
  {
    src: slides.slide4,
    altText: 'Slide 4',
    footer:'Step 4',
    caption: 'Provide behind the wheel training for 1 Hour along with helpful tips'
  },
  {
    src: slides.slide5,
    altText: 'Slide 5',
    footer:'Step 5',
    caption: 'Drop you back to  your preferred location'
  },
  {
    src: slides.slide6,
    altText: 'Slide 5',
    footer:'Step 5',
    caption: 'Drop you back to  your preferred location'
  }
];

class Gallery extends Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {

    const { activeIndex } = this.state;

    const slides = items.map((item) => {
        console.log(item.src);
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} width='100%' height={this.props.height} />
          <CarouselCaption captionText={item.footer} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        className="w-100"
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}


  export default Gallery;
