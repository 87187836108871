import React,{Component} from 'react';
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, CardText } from 'reactstrap';
import {  Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import FormErrors from './FormErrorsComponent';
import * as Constants from './Constants/constantData';
class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:'',
      name:'',
      phoneNumber:'',
      isFormValid:false,
      isEmailValid:false,
      isPasswordValid:false,
      userKey:'',
      loginError:'',
      formErrors:{
        email:'',
        password:''
      }
    }
    this.myRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPassword=this.forgotPassword.bind(this);
    
    
  }

  componentDidMount(){
    window.scrollTo(0, this.myRef.current.offsetTop);   
  }
  

  forgotPassword(event){
    this.props.history.push('/static/auth/ForgotPassword');
  }

  validateFields(fieldName,value){

    let fieldValidationErrors = this.state.formErrors;
      let emailValid = this.state.isEmailValid;
      let passwordValid = this.state.isPasswordValid;

    switch(fieldName) {
      case 'email':
        if(value.length <=1){
          fieldValidationErrors.email = 'Please enter email id';
        }
        else {
          const regexEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
          emailValid = regexEmail.test(value);
          fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        }
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': 'Password is too short';
        break;
      }

      this.setState({formErrors: fieldValidationErrors,
        isEmailValid:emailValid,
        isPasswordValid:passwordValid,
      }, this.validateForm);
}

    validateForm() {
    this.setState({isFormValid: this.state.isEmailValid
        && this.state.isPasswordValid
      });
  }

  

  handleChange(event) {
    
    const item = event.target.name;
    const value = event.target.value;
    this.setState({[item]:value}, () => { this.validateFields(item, value)});
    
 }

 handleSubmit(event) {
   //alert('Your favorite flavor is: ' + this.state.value);
   event.preventDefault();

   
   document.getElementById("spinner").style.display="block";

   fetch(Constants.URL+'/authenticate', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.state.email,
            password: this.state.password
          })
        }).then(res => res.json())
        .then((result) => {
          document.getElementById("spinner").style.display="none";
          if(result.status === 200) {

            

            const cookies = new Cookies();
            try{
            
            cookies.remove('shivaDStoken',{ path: '/',maxAge:1200,domain:Constants.SERVERIP,secure:Constants.SECURE });
            cookies.remove('shivaDStoken',{ path: '/',maxAge:1200,domain:Constants.DOMAINIP,secure:Constants.SECURE });
            }
            catch {
                
            }
            cookies.set('shivaDStoken', result.data , { path: '/',maxAge:1200,domain:Constants.SERVERIP,secure:Constants.SECURE });
            cookies.set('shivaDStoken', result.data , { path: '/',maxAge:1200,domain:Constants.DOMAINIP,secure:Constants.SECURE });
            this.setState({userKey : result.data });
            //this.props.isAuthed(result.Authorization);
            this.props.isAuthed(result.data);

            }
            else {
              this.setState({
                loginError:'Please check your username or password'
              });
            }
          }
          ,
        (error) => {
          document.getElementById("spinner").style.display="none";
            alert("Login Failed");
        });



 }

  render(){


    const errorMessage = () => {
      if(this.state.loginError !== '') {
      return (<Alert color="danger">
              {this.state.loginError}
              </Alert>)
        }
        else {
          return (<div></div>)
        }
    };

    if(this.state.userKey!=''){
      return <Redirect to='/static/userInfo' />
    }
    else{

    return(
      <div >
        
        <Card>
          <CardHeader>Sign In</CardHeader>
          <CardBody>
          <CardTitle></CardTitle>
            {errorMessage()}
            <Form onSubmit={this.handleSubmit}>
              <div className='container'>
              <div className='row'>
              <div className='col-sm-6'>
              <FormGroup>
                <Label for="email">Email-Id *:</Label>
                <Input type="text" name="email" required="required" value={this.state.email}
                    onChange={this.handleChange} />
              </FormGroup>
              </div>
              </div>
              <div className='row'>
              <div className='col-sm-6'>
              <FormGroup>
                <Label for="password">Password *:</Label>
                <Input type="password" name="password"
                value={this.state.password} onChange={this.handleChange} required="required"/>
              </FormGroup>
              </div>
              </div>
              <div className='row'>
              <div className='col-sm-6'>
              <FormErrors formErrors ={this.state.formErrors}/>
              </div>
              </div>
              <div className='row'>
              <div className='col-sm-2'>
              <FormGroup>
              <Label for="submit"></Label>
              <Input type="submit" value="Submit" className="btn btn-primary" disabled={!this.state.isFormValid} />
              <div id="spinner" className="loader"></div>
              </FormGroup>
              </div>
              </div>
              <div className='row'>
              <div className='col-sm-6'>
              <FormGroup>
              <Label for="forgotpassword"></Label>
                <Button variant="info" onClick={this.forgotPassword}>Forgot Password</Button>
              </FormGroup>
              </div>
              </div>
              </div>
            </Form>
          </CardBody>
        </Card>
        <div ref={this.myRef}></div>
      </div>
    
    );
    
    }
  }
 


}

export default Login;
